import {
  Box,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Spinner,
  SplitPanel,
  TextContent,
} from '@cloudscape-design/components';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { format } from 'date-fns';
import { useState } from 'react';
import { splitPanelI18nStrings } from 'src/i18n/split-panel';

import {
  updateUpgradeTemplate,
  useGetTeamMemberImpersonationToken,
  useGetUpgradeTemplate,
  useListAddonInstances,
  useListAddons,
  useListTeams,
} from 'api/admin';

import CopyText from 'components/copy-text';
import { UpgradeTemplateEditor } from './editor';
import {
  ActionButton,
  UpgradeBadgeStatus,
  TemporalBadgeStatus,
} from './helpers';
import {
  ValueWithLabel,
  ValueWithLabelEditButton,
} from 'components/value-with-label';
import HeaderItemsTable from './header';
import axios from 'axios';

export function UpgradeTemplateDetails({
  upgrade: item,
  onChange,
}: {
  upgrade: { accountId: string; upgradeId: string };
  onChange: () => void;
}) {
  const { data, mutate } = useGetUpgradeTemplate(
    item.accountId,
    item.upgradeId,
  );
  const [editorMode, setEditorMode] = useState<'edit' | 'create' | 'hidden'>(
    'hidden',
  );
  const [desiredVersionEditorVisible, setDesiredVersionEditorVisible] =
    useState(false);
  const [currentVersionEditorVisible, setCurrentVersionEditorVisible] =
    useState(false);
  const [planCreatorVisible, setPlanCreatorVisible] = useState(false);

  if (data === undefined) {
    return (
      <SplitPanel
        header={`Upgrade: ${item.upgradeId}`}
        i18nStrings={splitPanelI18nStrings}
      >
        <Spinner />
      </SplitPanel>
    );
  }

  return (
    <SplitPanel
      header={`Upgrade: ${data.upgrade.name}`}
      i18nStrings={splitPanelI18nStrings}
    >
      <DesiredVersionEditor
        accountId={item.accountId}
        upgradeId={item.upgradeId}
        desiredVersion={data.upgrade.current_version || ''}
        onDismiss={() => {
          void mutate();
          setDesiredVersionEditorVisible(false);
        }}
        visible={desiredVersionEditorVisible}
      />
      <CurrentVersionEditor
        accountId={item.accountId}
        upgradeId={item.upgradeId}
        currentVersion={data.upgrade.current_version || ''}
        onDismiss={() => {
          void mutate();
          setCurrentVersionEditorVisible(false);
        }}
        visible={currentVersionEditorVisible}
      />
      <UpgradeTemplateEditor
        mode={editorMode === 'hidden' ? 'edit' : editorMode}
        accountId={item.accountId}
        upgradeId={item.upgradeId}
        visible={editorMode !== 'hidden'}
        onCancel={() => setEditorMode('hidden')}
        onConfirm={() => {
          setEditorMode('hidden');
          mutate()
            .then(() => onChange())
            .catch((err) => console.log(err));
        }}
      />
      <SpaceBetween size="l">
        <Container>
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <TemplateToPlanCreator
                  accountId={data.account_id}
                  upgradeTemplateId={data.upgrade.id}
                  upgradeTemplateName={data.upgrade.name}
                  templateRequestedBy={data.upgrade.requested_by?.id || ''}
                  templateClusterId={
                    data.upgrade.resources.clusters &&
                    data.upgrade.resources.clusters.length
                      ? data.upgrade.resources.clusters[0]?.id
                      : ''
                  }
                  onDismiss={() => setPlanCreatorVisible(false)}
                  visible={planCreatorVisible}
                />
                <ActionButton
                  key="create-subscription"
                  onClick={() => setPlanCreatorVisible(true)}
                >
                  Create Add-on Upgrade Plan
                </ActionButton>
              </SpaceBetween>
            }
          >
            Create Upgrade Plans for Template
          </Header>
        </Container>

        {/* Upgrade Details Section */}
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {data.upgrade.status === 'in-progress' &&
                    data.creation_status === 'published' && (
                      <>
                        <ActionButton
                          key="mark-as-complete"
                          onConfirm={async () => {
                            if (
                              confirm(
                                `Are you sure you want to mark this upgrade as completed?`,
                              )
                            ) {
                              await updateUpgradeTemplate(
                                data.account_id,
                                data.upgrade.id,
                                {
                                  status: 'completed',
                                },
                              );
                              mutate()
                                .then(() => onChange())
                                .catch((err) => console.log(err));
                            }
                          }}
                        >
                          Mark As Completed
                        </ActionButton>
                        <ActionButton
                          key="mark-as-outdated"
                          onConfirm={async () => {
                            await updateUpgradeTemplate(
                              data.account_id,
                              data.upgrade.id,
                              {
                                status: 'outdated',
                              },
                            );
                            mutate()
                              .then(() => onChange())
                              .catch((err) => console.log(err));
                          }}
                        >
                          Mark As Outdated
                        </ActionButton>
                      </>
                    )}
                  <ActionButton
                    key="assign-to-me"
                    onConfirm={async () => {
                      const user =
                        (await Auth.currentAuthenticatedUser()) as CognitoUser;
                      const session = user.getSignInUserSession();
                      await updateUpgradeTemplate(
                        data.account_id,
                        data.upgrade.id,
                        {
                          assignee: session?.getIdToken().payload
                            .email as string,
                          creation_status: 'in-progress',
                        },
                      );
                      mutate()
                        .then(() => onChange())
                        .catch((err) => console.log(err));
                    }}
                  >
                    Assign to me
                  </ActionButton>
                  {data.upgrade.status === 'pending' &&
                    data.creation_status === 'in-progress' && (
                      <ActionButton
                        key="publish-for-review"
                        onConfirm={async () => {
                          if (data.upgrade.status === 'pending') {
                            await updateUpgradeTemplate(
                              data.account_id,
                              data.upgrade.id,
                              {
                                creation_status: 'in-review',
                              },
                            );
                            mutate()
                              .then(() => onChange())
                              .catch((err) => console.log(err));
                          }
                        }}
                      >
                        Publish for review
                      </ActionButton>
                    )}
                  {data.upgrade.status === 'pending' &&
                    data.creation_status === 'in-review' && (
                      <ActionButton
                        key="publish-to-customer"
                        onConfirm={async () => {
                          if (data.upgrade.status === 'pending') {
                            await updateUpgradeTemplate(
                              data.account_id,
                              data.upgrade.id,
                              {
                                creation_status: 'published',
                                status: 'in-progress',
                              },
                            );
                            mutate()
                              .then(() => onChange())
                              .catch((err) => console.log(err));
                          }
                        }}
                      >
                        Publish to customer
                      </ActionButton>
                    )}
                  {(data.upgrade.status === 'regenerate-in-progress' ||
                    (data.upgrade.status === 'update-in-progress' &&
                      data.creation_status === 'published')) && (
                    <ActionButton
                      key="republish-to-customer"
                      onConfirm={async () => {
                        await updateUpgradeTemplate(
                          data.account_id,
                          data.upgrade.id,
                          {
                            last_validated: Date.now(),
                            status: 'in-progress',
                          },
                        );
                        mutate()
                          .then(() => onChange())
                          .catch((err) => console.log(err));
                      }}
                    >
                      Re-Publish to customer
                    </ActionButton>
                  )}
                  {data.temporal_status && (
                    <Button
                      key="show-template"
                      onClick={() => {
                        setEditorMode('edit');
                      }}
                    >
                      Show Upgrade Template JSON
                    </Button>
                  )}
                  {(!data.temporal_status ||
                    data.temporal_status === 'Terminated') && (
                    <Button
                      key="create-template"
                      onClick={() => setEditorMode('create')}
                    >
                      Create Upgrade Template
                    </Button>
                  )}
                </SpaceBetween>
              }
            >
              Upgrade details
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Account ID">
                <CopyText
                  copyText={data.account_id}
                  copyButtonLabel="Copy Account ID"
                  successText="Account ID copied"
                  errorText="Account ID failed to copy"
                />
              </ValueWithLabel>
              <ValueWithLabel label="ID">
                <CopyText
                  copyText={data.upgrade.id}
                  copyButtonLabel="Copy Upgrade ID"
                  successText="Upgrade ID copied"
                  errorText="Upgrade ID failed to copy"
                />
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Name">{data.upgrade.name}</ValueWithLabel>
              <ValueWithLabel label="Requested By">
                {data.upgrade.requested_by?.name || '-'} (
                {data.upgrade.requested_by?.email || '-'})
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Status">
                <UpgradeBadgeStatus value={data.upgrade.status} />
              </ValueWithLabel>
              <ColumnLayout columns={2} variant="text-grid">
                <ValueWithLabel label="Created">
                  {format(data.upgrade.created, 'Pp')}
                </ValueWithLabel>
                {data.upgrade.updated && (
                  <ValueWithLabel label="Last Update">
                    {format(data.upgrade.updated, 'Pp')}
                  </ValueWithLabel>
                )}
              </ColumnLayout>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        {/* Creation Details Section */}
        <Container header={<Header variant="h2">Creation Details</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="xs">
              <ValueWithLabel label="Assignee">
                {data.assignee || '-'}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <ValueWithLabel label="Creation Status">
                <UpgradeBadgeStatus value={data.creation_status} />
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <ValueWithLabel label="Temporal Status">
                <TemporalBadgeStatus value={data.temporal_status} />
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        {/* Resources */}
        <Container header={<Header variant="h2">Resources</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="xs">
              <Box variant="awsui-key-label">Clusters</Box>
              <TextContent>
                {(data.upgrade.resources.clusters || []).map((c) => (
                  <CopyText
                    key={c.id}
                    copyText={c.id}
                    copyButtonLabel="Copy Cluster ID"
                    successText="Cluster ID copied"
                    errorText="Cluster ID failed to copy"
                  />
                ))}
                {(data.upgrade.resources.clusters === undefined ||
                  data.upgrade.resources.clusters.length === 0) && <p>-</p>}
              </TextContent>
              <ColumnLayout columns={2} variant="text-grid">
                <ValueWithLabelEditButton
                  label="Current Version"
                  onConfirm={() => setCurrentVersionEditorVisible(true)}
                >
                  {data.upgrade.current_version || '-'}
                </ValueWithLabelEditButton>
                <ValueWithLabelEditButton
                  label="Desired Version"
                  onConfirm={() => setDesiredVersionEditorVisible(true)}
                >
                  {data.upgrade.desired_version || '-'}
                </ValueWithLabelEditButton>
              </ColumnLayout>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <Box variant="awsui-key-label">Addons</Box>
              <TextContent>
                {(data.upgrade.resources.addons || []).map((c) => (
                  <CopyText
                    key={c.id}
                    copyText={c.id}
                    copyButtonLabel="Copy Addon ID"
                    successText="Addon ID copied"
                    errorText="Addon ID failed to copy"
                  />
                ))}
                {(data.upgrade.resources.addons === undefined ||
                  data.upgrade.resources.addons.length === 0) && <p>-</p>}
              </TextContent>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <Box variant="awsui-key-label">Addon Instances</Box>
              <TextContent>
                {(data.upgrade.resources.addon_instances || []).map((c) => (
                  <CopyText
                    key={c.id}
                    copyText={c.id}
                    copyButtonLabel="Copy Addon Instance ID"
                    successText="Addon Instance ID copied"
                    errorText="Addon Instance ID failed to copy"
                  />
                ))}
                {(data.upgrade.resources.addon_instances === undefined ||
                  data.upgrade.resources.addon_instances.length === 0) && (
                  <p>-</p>
                )}
              </TextContent>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        {/* Headings Info bar */}
        <HeaderItemsTable
          editable={false}
          initialItems={data.upgrade.header?.items || []}
          onSave={async (newItems) => {
            try {
              await updateUpgradeTemplate(data.account_id, data.upgrade.id, {
                header: { items: newItems },
              });
              await mutate();
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </SpaceBetween>
    </SplitPanel>
  );
}

function ProcessingDialogModal(props: {
  header: string;
  onConfirm: () => Promise<void>;
  onDismiss: () => void;
  isError?: boolean;
  visible: boolean;
  children: React.ReactNode;
}): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onConfirmClick = async () => {
    setProcessing(true);
    try {
      await props.onConfirm();
    } catch (err: unknown) {
      console.error(err);
    }
    setProcessing(false);
    props.onDismiss();
  };

  return (
    <Modal
      header={props.header}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        {props.children}
        <SpaceBetween direction="horizontal" size="xs">
          <ActionButton
            key="confirm"
            onConfirm={onConfirmClick}
            disabled={processing || props.isError}
          >
            Confirm
          </ActionButton>
          <ActionButton key="cancel" onClick={props.onDismiss}>
            Cancel
          </ActionButton>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}

function TemplateToPlanCreator(props: {
  accountId: string;
  upgradeTemplateId: string;
  upgradeTemplateName: string;
  templateRequestedBy: string;
  templateClusterId: string;
  onDismiss: () => void;
  visible: boolean;
}): JSX.Element {
  const { data: accountTeams } = useListTeams(props.accountId);
  const teamId = accountTeams?.data?.length ? accountTeams?.data[0].id : '';

  const { data: federatedTokenData } = useGetTeamMemberImpersonationToken(
    props.accountId,
    teamId,
    props.templateRequestedBy,
    {
      swr: {
        refreshInterval: 10 * 60 * 1000,
      },
    },
  );
  const federatedToken = federatedTokenData?.access_token.token || '';

  const { data: addonData } = useListAddons({ page_size: 1000 });
  const addonsList = addonData?.data || [];

  const { data: accountAddonInstancesData } = useListAddonInstances(
    props.accountId,
  );
  const clusterAddonInstances =
    accountAddonInstancesData?.data?.filter(
      (ai) => ai.cluster_id === props.templateClusterId,
    ) || [];

  // Create a list of {name, id} using clusterAddonInstances and addonsList
  const clusterAddonsNameIdList = clusterAddonInstances.map((ai) => {
    const matchingAddon = addonsList.find((addon) => addon.id === ai.addon_id); // Find the addon in addonsList

    return {
      id: ai.id, // ID from clusterAddonInstance
      name: matchingAddon?.name || '',
    };
  });

  const [selectedAddonInstance, setSelectedAddonInstance] = useState({
    id: '',
    name: '',
  });

  const [plaName, setPlanName] = useState(
    selectedAddonInstance?.name
      ? `${selectedAddonInstance.name}-${props.upgradeTemplateName}`
      : '',
  );

  return (
    <ProcessingDialogModal
      header="Create Add-on Upgrade Plan from Template"
      onConfirm={async () => {
        if (selectedAddonInstance.id) {
          try {
            // call to chkk api on user's behalf
            const response = await axios.post(
              `${import.meta.env.VITE_CHKK_API_ENDPOINT}/upgrades`,
              {
                deployment_strategy: 'pick-for-me',
                name: plaName,
                resources: {
                  addon_instances: [{ id: selectedAddonInstance.id }],
                },
                upgrade_template_id: props.upgradeTemplateId,
              },
              {
                headers: {
                  Authorization: `Bearer ${federatedToken}`,
                },
              },
            );
          } catch (error) {
            console.error('Error making POST request:', error);
          }
        } else {
          console.error('No addon instance selected');
        }
      }}
      onDismiss={() => {
        setSelectedAddonInstance({ id: '', name: '' });
        setPlanName('');
        props.onDismiss();
      }}
      visible={props.visible}
    >
      <Container>
        <SpaceBetween size="xxl">
          <SpaceBetween size="xl">
            <ValueWithLabel label="Upgrade Template ID">
              <CopyText
                copyText={props.upgradeTemplateId}
                copyButtonLabel="Copy Upgrade Template ID"
                successText="Upgrade Template ID copied"
                errorText="Upgrade Template ID failed to copy"
              />
            </ValueWithLabel>
            <ValueWithLabel label="Cluster ID">
              <CopyText
                copyText={props.templateClusterId}
                copyButtonLabel="Cluster ID"
                successText="Cluster ID copied"
                errorText="Cluster ID failed to copy"
              />
            </ValueWithLabel>
            <ValueWithLabel label="Requested by User ID">
              <CopyText
                copyText={props.templateRequestedBy}
                copyButtonLabel="Copy Requested by User ID"
                successText="Requested by User ID copied"
                errorText="Requested by User ID failed to copy"
              />
            </ValueWithLabel>
            <ValueWithLabel label="Account ID">
              <CopyText
                copyText={props.accountId}
                copyButtonLabel="Copy Account ID"
                successText="Account ID copied"
                errorText="Account ID failed to copy"
              />
            </ValueWithLabel>
            <ValueWithLabel label="Team ID">
              {teamId ? (
                <CopyText
                  copyText={teamId}
                  copyButtonLabel="Team ID"
                  successText="Team ID copied"
                  errorText="Team ID failed to copy"
                />
              ) : (
                'fetching...'
              )}
            </ValueWithLabel>
          </SpaceBetween>
          <FormField label="Set Upgrade Plan Name">
            <Input
              value={plaName}
              placeholder="Enter the name of the plan"
              onChange={({ detail }) => {
                setPlanName(detail.value);
              }}
            />
          </FormField>

          <Select
            selectedOption={{
              label: selectedAddonInstance?.name || 'Select Addon Instance',
              value: selectedAddonInstance?.id,
            }}
            onChange={(event) => {
              setSelectedAddonInstance({
                id: event.detail.selectedOption.value || '',
                name: event.detail.selectedOption.label || '',
              });
              setPlanName(
                event.detail.selectedOption?.label
                  ? `${event.detail.selectedOption.label}-${props.upgradeTemplateName}`
                  : '',
              );
            }}
            options={clusterAddonsNameIdList.map((ai) => ({
              value: ai.id,
              label: ai.name,
            }))}
          />
        </SpaceBetween>
      </Container>
    </ProcessingDialogModal>
  );
}

function DesiredVersionEditor(props: {
  accountId: string;
  upgradeId: string;
  desiredVersion: string;
  onDismiss: () => void;
  visible: boolean;
}): JSX.Element {
  const [desiredVersion, setDesiredVersion] = useState(props.desiredVersion);

  return (
    <ProcessingDialogModal
      header="Change Desired Version"
      onConfirm={async () => {
        await updateUpgradeTemplate(props.accountId, props.upgradeId, {
          desired_version: desiredVersion,
        });
      }}
      onDismiss={() => {
        if (props.desiredVersion === desiredVersion) {
          setDesiredVersion(props.desiredVersion);
        }
        props.onDismiss();
      }}
      visible={props.visible}
    >
      <FormField label="Desired Upgrade Version">
        <Input
          value={desiredVersion}
          onChange={({ detail }) => {
            setDesiredVersion(detail.value);
          }}
        />
      </FormField>
    </ProcessingDialogModal>
  );
}
function CurrentVersionEditor(props: {
  accountId: string;
  upgradeId: string;
  currentVersion: string;
  onDismiss: () => void;
  visible: boolean;
}): JSX.Element {
  const [currentVersion, setCurrentVersion] = useState(props.currentVersion);

  return (
    <ProcessingDialogModal
      header="Change CurrentVersion"
      onConfirm={async () => {
        await updateUpgradeTemplate(props.accountId, props.upgradeId, {
          current_version: currentVersion,
        });
      }}
      onDismiss={() => {
        if (props.currentVersion === currentVersion) {
          setCurrentVersion(props.currentVersion);
        }
        props.onDismiss();
      }}
      visible={props.visible}
    >
      <FormField label="Current Upgrade Version">
        <Input
          value={currentVersion}
          onChange={({ detail }) => {
            setCurrentVersion(detail.value);
          }}
        />
      </FormField>
    </ProcessingDialogModal>
  );
}
